<template>
  <div className="main-content">
    <div style="font-size: 30px; font-weight: bold;text-align: center">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {
      title: this.$title,
      data: [
        {
          label: '一级 1',
          value: '1',
        },
        {
          label: '一级 2',
          value: '2',
        },
        {
          label: '一级 3',
          value: '3',
        }
      ]
    }
  },
  mounted() {
    // this.fun(0).then(()=> {
    //   console.log('%c欢迎进入房产后台管理', 'color: #43bb88;font-size: 30px;font-weight: bold;text-decoration: underline;')
    // })
  },
  methods: {
    // fun(time){
    //   return new Promise((resolve) => {
    //     setTimeout(() => {
    //       resolve(time)
    //     }, time)
    //   })
    // }

  }
}
</script>

<style scoped>
.avue-data-panel >>> .item {
  margin-bottom: 20px;
}

.avue-data-panel >>> .iconfont {
  font-size: 30px;
}
</style>
